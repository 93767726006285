<template>
  <v-dialog v-model="show" max-width="400px">
    <v-card>
      <v-toolbar flat dense color="grey lighten-2">
        <v-toolbar-title>
          Adicionar Imagem
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon small @click="show = false">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider rules="required" v-slot="{ errors }" ref="image" name="imagem">
                <v-file-input
                  v-model="image"
                  accept="image/png, image/jpeg, image/bmp"
                  hide-details="auto"
                  required
                  label="Imagem"
                  hint="Selecione a imagem que deseja adicionar (Max.: 10MB)"
                  persistent-hint
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" outlined :loading="loadingSave" @click="save">
          <v-icon v-text="'mdi-content-save'" left />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import productsImagesApi from '@/api/products-images'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loadingSave: false,
      image: null,
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    title() {
      return this.productId ? 'Editar' : 'Adicionar'
    },
  },

  methods: {
    async save() {
      try {
        this.loadingSave = true

        const validate = await this.$refs.image.validate()

        if(!validate.valid) return

        const formData = new FormData()
        formData.append('image', this.image)

        await productsImagesApi.insert(this.productId, formData)

        this.image = null
        this.$refs.observer.reset()
        this.$emit('save')
      } catch (e) {
        console.log(e)
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingSave = false
      }
    },
  },
}
</script>
